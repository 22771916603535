@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap");

:root {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
:root {
  --toastify-color-success: #def7ec;
  --toastify-color-warning: #fdf6b2;
  --toastify-color-error: #fde8e8;

  --toastify-text-color-success: #046c4e;
  --toastify-text-color-warning: #8e4b10;
  --toastify-text-color-error: #c81e1e;
}

.Toastify__close-button {
  color: #000;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  background-color: #046c4e;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  background-color: #8e4b10;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background-color: #c81e1e;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-main {
  height: 100vh;
  width: 100vw;
}

.iframe-container {
  height: 600px;
  width: 100%;
  border: none;
}

::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

// /* Track */
// ::-webkit-scrollbar-track {
//   // background: #fff;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

a {
  text-decoration: none;
}

.sidebar.collapsed {
  width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar {
  height: 100vh;
  width: 224px;
  // transition: width 0.2s;
  transition: width 0.2s ease-in;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  border-right: 1px solid #e5e7eb;
  padding: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sider-menu {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 12px;
    cursor: pointer;
    // .side-bar-icon {
    //   path {
    //     fill: #ffffff;
    //   }
    // }
  }

  // .side-bar-icon {
  //   .side-bar-icon-rect {
  //     fill: rgba(255, 255, 255, 0.1);
  //   }
  //   path {
  //     fill: #327091;
  //   }
  // }
  // }
}
.user-management-menu {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 0px 16px 40px;
  border-radius: 12px;
  cursor: pointer;
  // .side-bar-icon {
  //   path {
  //     fill: #ffffff;
  //   }
  // }
  font-weight: 100;
}
.active.user-management-menu {
  .MuiTypography-root {
    font-weight: 600;
  }

  // .side-bar-icon {
  //   .side-bar-icon-rect {
  //     fill: rgba(255, 255, 255, 0.1);
  //   }
  //   path {
  //     fill: #327091;
  //   }
  // }
}

.rearrange-section {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  margin-bottom: 12px;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  .shuffle-handler {
    margin-right: 15px;
  }
}

.wmde-markdown {
  font-family: "Poppins", sans-serif;
}
.w-md-editor {
  .w-md-editor-toolbar {
    height: 50px;
    padding: 8px;
    ul {
      li {
        button {
          width: 24px;
        }
      }
    }
  }
}

.study-print-container {
  .print-button {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    @media print {
      display: none;
    }
  }
  .print-form-container {
    margin-bottom: 20px;
    padding: 20px;
    .print-crf-table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      .crf-table-row,
      .crf-table-header {
        & > td,
        & > th {
          border-bottom: 1px solid #e5e7eb;
          padding: 10px;
          text-align: left;
        }
        & > td {
          font-size: 16px;
        }
      }
    }
  }
  .print-list-table {
    border-collapse: collapse;
    td,
    th {
      border: 1px solid #e5e7eb;
      min-width: 150px;
      padding: 10px;
    }
  }
}
